@import './vars';
$toolbar-back-color-base: #888;
@mixin toolbar-btn($width: 44px, $padding: 6px) {
    background: transparent;
    outline: none;
    width: $width;
    height: $width;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: $padding;
    border: 1px solid transparent;
    border-radius: $padding * 1.5;
    &:hover {
        background-color: rgba($toolbar-back-color-base, 0.2);
    }
    &:active {
        border-color: rgba($toolbar-back-color-base, 0.5);
    }
    &.active {
        background-color: rgba($toolbar-back-color-base, 0.5);
        &:hover {
            background-color: rgba($toolbar-back-color-base, 0.6);
        }
    }
    &.checked,
    &.active {
        border-color: darken($primary-hilight-color, 20%);
        box-shadow: inset 0px 0px 0px 2px lighten($primary-hilight-color, 25%);
        background-color: #fff;
        &:hover {
            background-color: #fff;
        }
    }
    .x-icon {
        pointer-events: none;
    }
    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.45;
        .x-icon {
            color: darken($grey-line-color, 25);
        }
        &:hover {
            background: transparent;
        }
    }
}