@import './vars';

.x-dock-layout-heading {
    position: absolute;
    top: 0;
    left: $taskbar-width;
    z-index: 11;
    width: calc(100vw - #{$taskbar-width});
    text-align: center;
    padding-top: 0.5em;
}

@mixin close_button {
    cursor: pointer;
    float: right;
    width: 30px;
    height: 30px;
    margin: 0;
    flex-shrink: 0;
    background: none !important;
    &::after {
        content: "";
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgNi40MUwxNy41OSA1IDEyIDEwLjU5IDYuNDEgNSA1IDYuNDEgMTAuNTkgMTIgNSAxNy41OSA2LjQxIDE5IDEyIDEzLjQxIDE3LjU5IDE5IDE5IDE3LjU5IDEzLjQxIDEyeiIvPjwvc3ZnPg==);
        width: 26px;
        height: 26px;
        display: block;
        margin: 2px;
        border-radius: 13px;
        opacity: 0.5;
    }
    &:active::after {
        opacity: 0.75;
    }
    &:hover::after{
        background-color: #aaa;
    }
}

.flexlayout__outline_rect {
    border-color: darken($important-color, 20%);
}

.x-dock-layout-add-panel-hint {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(105, 105, 105, 0.36);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        cursor: pointer;
        background: $view-body-back-color;
        padding: 0.75em 1.25em;
        white-space: pre-wrap;
        border: 2px solid $important-color;
        border-radius: $flexlayout-corner-radius;
        box-shadow: 10px 10px 2em 0 rgba(0, 0, 0, 0.5);
        &:hover {
            border-color: darken($important-color, 20%);
        }
        h2 {
            font-weight: 500;
            font-size: 1.23rem;
        }
        button.close {
            @include close_button;
            margin-top: -0.5em;
            margin-right: -0.6em;
            padding: 0;
            border: 0;
        }
    }
}

.x-dock-layout-dragging-hint {
    display: block;
    text-align: center;
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100vw;
    pointer-events: none;
    & > * {
        display: inline-block;
        text-align: left;
        padding: 0.75em 1.5em;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border: 1px solid $important-color;
        border-bottom: 0;
        white-space: pre-wrap;
        background: mix($view-body-back-color, $important-color, 50%);
        box-shadow: 1px 1px 17px 2px rgba(0, 0, 0, 0.3);
    }
    &::before {
        content: "";
        display: block;
        border: 2px solid red;
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        display: block;
        z-index: 1;
        pointer-events: none;
    }
}

.x-dock-layout-container {
    .flexlayout__layout {
        background-color: $flexlayout-back-color;
    }

    .flexlayout__tab_header {
        &_outer,
        &_inner {
            width: 100%;
            font-weight: $flexlayout-tab-header-font-weight;
            font-size: $flexlayout-tab-header-font-size;
        }
    }

    .flexlayout__splitter {
        background-color: $flexlayout-back-color;
        &:hover {
            background-color: $flexlayout-back-color;
        }
        &_drag {
            background-color: rgba(darken($flexlayout-back-color, 20%), 0.5);
        }
    }

    .flexlayout__tabset {
        border-radius: $flexlayout-corner-radius;
        background: $view-body-back-color;
    }

    .flexlayout__border {
        &_left,
        &_top,
        &_right,
        &_bottom {
            background-color: $flexlayout-back-color;
            border: 0;
        }
    }

    .flexlayout__tab {
        border-radius: $flexlayout-corner-radius;
        background: white;
    }

    .flexlayout__tab_button {
        cursor: default;
        display: flex;
        margin: 0;
        border-radius: 0;
        float: none;
        line-height: 30px;
        padding: 0 0 0 10px;
        box-shadow: none;

        &_content {
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
            overflow: hidden;
            font-weight: bold;
            line-height: 14px;
            text-transform: capitalize;
        }

        &--selected {
            background-color: transparent;
        }

        &_trailing {
            @include close_button;
        }
    }

    .flexlayout__tabset-selected {
        background-image: none;
    }
}