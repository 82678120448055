@import "src/style/vars";

$table-body-background: mix($view-body-back-color, $grey-back-color, 80%);

.x-datatable {
  position: relative;

  // auto size mode
  &__autoSize {
    position: relative;
    & > iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 0;
      opacity: 0;
      pointer-events: none;
    }
  }

  // no rows message
  &__noRowsMessage {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    left: 0;
    top: 0;
    align-items: center;
    & > i {
      display: block;
      text-align: center;
      width: 100%;
      font-style: normal;
      color: $grey-line-color;
    }

    .error {
      color: $danger-color;
    }
  }
}

.public_fixedDataTable_main {
  border: 0;
  background: $table-body-background;
}

.public_fixedDataTableRow_fixedColumnsDivider {
  border-color: rgba($view-body-text-color, 0.15);
}
.public_fixedDataTableRow_columnsShadow.public_fixedDataTableRow_columnsRightShadow {
  margin-left: 1px;
}

.public_fixedDataTableRow_main {
  border-bottom: 1px solid rgba($view-body-text-color, 0.15);
  &.selected {
    &.public_fixedDataTableRow_highlighted,
    &.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
      background-color: mix($primary-hilight-color, $view-body-back-color, 30%);
    }
    &.public_fixedDataTableRow_main,
    &.public_fixedDataTableRow_main .public_fixedDataTableCell_main {
      background-color: mix($primary-hilight-color, $view-body-back-color, 30%);
    }

    &:hover.public_fixedDataTableRow_highlighted,
    &:hover.public_fixedDataTableRow_highlighted
      .public_fixedDataTableCell_main {
      background-color: mix($primary-hilight-color, #f6f7f8, 40%);
    }
    &:hover.public_fixedDataTableRow_main,
    &:hover.public_fixedDataTableRow_main .public_fixedDataTableCell_main {
      background-color: mix($primary-hilight-color, #f6f7f8, 40%);
    }
  }

  &.public_fixedDataTableRow_highlighted,
  &.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background: mix($view-body-back-color, $table-body-background);
  }

  .public_fixedDataTableCell_main {
    border-right: 0;
  }

  .public_fixedDataTableCell_cellContent {
    padding: 0.6rem 0.25rem;
  }

  &.x-datatable__groupHeaderRow {
    background: $view-body-back-color !important;

    .fixedDataTableCellGroupLayout_cellGroup {
      width: 100% !important;
    }

    .public_fixedDataTableCell_main:first-child {
      width: 100% !important;
    }
    .public_fixedDataTableCell_main + .public_fixedDataTableCell_main {
      width: 0 !important;
    }

    .public_fixedDataTableCell_main {
      background-color: rgba(125, 139, 230, 0.25);
    }
  }

  &.public_fixedDataTable_footer {
    background: #f6f7f8;
    border-top: 1px solid #d3d3d3;

    .public_fixedDataTableCell_main {
      border-top: 0;
    }
  }

  .ui.fitted.checkbox {
    margin-top: 0;
    > label {
      margin: 0;
    }
  }

  .ui.buttons {
    margin: -0.5rem;
  }

  .ui.basic.button {
    color: $primary-hilight-color !important;
    box-shadow: none;
  }

  .fixedDataTableCellLayout_columnResizerContainer {
    border-right: 1px solid rgba($view-body-text-color, 0.05);
  }
  .x-wrap-cell {
    white-space: nowrap;
  }
}
