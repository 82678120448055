@import './vars';
@import './toolbar';
@import './mixins';
$transition-timing: 0.5s 0.35s;
.x-chart-panel {
  &__top {
    position: absolute;
    background-color: transparent;
    top: -36px;
    width: 100%;
    text-align: center;
    border-radius: $flexlayout-corner-radius;
    z-index: 10;
    border-bottom: 5px solid transparent;
    @include transition(top $transition-timing);
    &:hover {
      top: 0;
      .x-chart-panel__toolbar {
        // height: auto;
        overflow: initial;
        background-color: darken($grey-back-color, 5%);
      }
      .x-chart-panel__toolbar-inner {
        opacity: 1;
      }
    }
  }
  &__toolbar {
    display: inline-block;
    background-color: #e68f7d;
    border-radius: $flexlayout-corner-radius;
    padding: 4px 5px;
    height: 42px;
    max-width: 100%;
    @include transition(background-color $transition-timing);
  }
  &__toolbar-inner {
    opacity: 0;
    display: flex;
    align-items: center;
    @include transition(opacity $transition-timing);
    > * {
      margin: 0 2px;
      display: inline-block;
    }
    > button {
      @include toolbar-btn($width: 32px, $padding: 3px);
    }
    .ui.dropdown {
      padding: 0.3em 1.5em 0.3em .5em;
      min-height: 1.8em;
      margin: 3px 2px;
      > .dropdown.icon {
        padding: 0.5em .3em;
      }
      > .menu > .item {
        padding: 0.5em 0.75em !important;
      }
    }
  }

  &__view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    border: $orange-border-width solid $orange-border-color;
    border-top: 0;

    .x-grid-chart {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;

      .x-datatable__cell {
        width: 100%;
      }
    }

    .x-no-content {
      margin-top: 1.5em;
      text-align: center;
    }
  }
  &__filter + &__view {
    padding-bottom: 20px;
  }
  &__filter {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    width: 100%;
    background-color: mix($view-body-back-color, $important-color, 80%);
    border-top: 1px solid mix($view-body-back-color, $important-color);
    border-left: $orange-border-width solid $orange-border-color;
    border-right: $orange-border-width solid $orange-border-color;
    border-bottom: $orange-border-width solid $orange-border-color;
    border-top-left-radius: $flexlayout-corner-radius;
    border-top-right-radius: $flexlayout-corner-radius;
    z-index: 10;
    &:hover {
      height: auto;
      border-bottom: none;
      .x-chart-panel__filter-body {
        display: block;
      }
      .x-chart-panel__filter-header {
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.1);
        &::after {
          content: "";
        }
      }
    }
    &-body {
      display: none;
      overflow: hidden;
      padding: 0px 8px;
      ul {
        padding: 0;
        margin: 0.5em 0;
      }
      li {
        display: inline-block;
        max-width: 20em;
        text-overflow: ellipsis;
        overflow: hidden;
        list-style: none;
        margin-right: 1em;
      }
    }
    &-header {
      text-align: center;
      padding: 1px 8px;
      &::after {
        content: "...";
      }
      @include transition(background-color 0.5s);
    }
    & + .x-chart-panel__view {
      padding-bottom: 20px;
    }
  }
  &__notify {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -1em;
    line-height: 1.1em;
  }
  &__resizeIframe{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    border: none;
    z-index: -1;
  }
}

#x-chartjs-tooltip {
  font-size: 0.9rem;
  padding: 4px 6px;
  pointer-events: none;
  color: $inverted-text-color;
  background-color: $inverted-back-color;
  border-radius: 3px;
  position: fixed;
  z-index: 100;
  white-space: pre-wrap;
}
