@charset "UTF-8";
/* Transition mixins */
/* animation mixin */
/* Filter */
/* Transform mixins */
/* Calculate text color base on background color of container element */
.x-tab-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .x-tab-container > * {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .x-tab-container > * > .ui.menu {
      flex-grow: 0; }
    .x-tab-container > * > .ui.tab {
      flex-grow: 1;
      display: flex; }
      .x-tab-container > * > .ui.tab > * {
        flex-grow: 1; }

.ui.input > .x-icon {
  width: 20px;
  height: 20px;
  margin: 7px 0 0 -28px;
  pointer-events: none; }

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 0; }

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.3); }

.ui.label:empty {
  display: none !important; }

.ui.form .field > input:focus,
.ui.form .field > label > input:focus,
.ui.input > input:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.x-selectbox > .ui.button:focus {
  border-color: #217cf3;
  box-shadow: inset 0 0 0 0.2rem rgba(33, 124, 243, 0.3); }

.ui.form .fields.wrap {
  flex-wrap: wrap; }
  .ui.form .fields.wrap .small.buttons {
    margin: 4px 0; }
    .ui.form .fields.wrap .small.buttons > .ui.button {
      padding: 0.5em 1em; }

.ui.dropdown > .text {
  color: #000; }

.ui.modal {
  position: static; }
  .ui.modal > .close.icon {
    top: 0;
    right: 0;
    opacity: 0.5;
    font-size: 2rem;
    padding: 0.25em;
    width: 1.25em; }
    .ui.modal > .close.icon::before {
      content: "\D7";
      color: #000; }
    .ui.modal > .close.icon:hover {
      opacity: 1; }
  .ui.modal > .header {
    background: #eaeaea;
    border-bottom: none; }
  .ui.modal > .content {
    background: #eaeaea; }
  .ui.modal > .actions {
    background: #eaeaea;
    border-top: none; }
    .ui.modal > .actions .ui.button {
      min-width: 7em; }

.ui.inverted.popup {
  background: #696969; }
  .ui.inverted.popup:before {
    background: #696969 !important; }

.blurring.dimmed.dimmable > :not(.dimmer) {
  filter: none;
  -webkit-filter: none; }

.blurring.dimmed.dimmable > #root {
  filter: blur(5px) grayscale(0.7);
  -webkit-filter: blur(5px) grayscale(0.7); }

.ui.button.x-disabled, a.item.x-disabled {
  cursor: not-allowed;
  opacity: 0.45; }

.ui.button {
  font-weight: normal !important;
  color: #000;
  background: rgba(0, 0, 0, 0.1); }
  .ui.button:hover {
    color: #000;
    background: rgba(0, 0, 0, 0.15); }

.ui.table td {
  padding-top: .3em !important;
  padding-bottom: .3em !important; }

.splitter-layout {
  position: relative !important; }
  .splitter-layout > .layout-splitter {
    background: #ddd;
    opacity: 0; }
    .splitter-layout > .layout-splitter:hover {
      opacity: 0.3; }

.x-dock-layout-heading {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 11;
  width: calc(100vw - 0px);
  text-align: center;
  padding-top: 0.5em; }

.flexlayout__outline_rect {
  border-color: #d44629; }

.x-dock-layout-add-panel-hint {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(105, 105, 105, 0.36);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center; }
  .x-dock-layout-add-panel-hint > * {
    cursor: pointer;
    background: white;
    padding: 0.75em 1.25em;
    white-space: pre-wrap;
    border: 2px solid #E68F7D;
    border-radius: 7px;
    box-shadow: 10px 10px 2em 0 rgba(0, 0, 0, 0.5); }
    .x-dock-layout-add-panel-hint > *:hover {
      border-color: #d44629; }
    .x-dock-layout-add-panel-hint > * h2 {
      font-weight: 500;
      font-size: 1.23rem; }
    .x-dock-layout-add-panel-hint > * button.close {
      cursor: pointer;
      float: right;
      width: 30px;
      height: 30px;
      margin: 0;
      flex-shrink: 0;
      background: none !important;
      margin-top: -0.5em;
      margin-right: -0.6em;
      padding: 0;
      border: 0; }
      .x-dock-layout-add-panel-hint > * button.close::after {
        content: "";
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgNi40MUwxNy41OSA1IDEyIDEwLjU5IDYuNDEgNSA1IDYuNDEgMTAuNTkgMTIgNSAxNy41OSA2LjQxIDE5IDEyIDEzLjQxIDE3LjU5IDE5IDE5IDE3LjU5IDEzLjQxIDEyeiIvPjwvc3ZnPg==);
        width: 26px;
        height: 26px;
        display: block;
        margin: 2px;
        border-radius: 13px;
        opacity: 0.5; }
      .x-dock-layout-add-panel-hint > * button.close:active::after {
        opacity: 0.75; }
      .x-dock-layout-add-panel-hint > * button.close:hover::after {
        background-color: #aaa; }

.x-dock-layout-dragging-hint {
  display: block;
  text-align: center;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100vw;
  pointer-events: none; }
  .x-dock-layout-dragging-hint > * {
    display: inline-block;
    text-align: left;
    padding: 0.75em 1.5em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border: 1px solid #E68F7D;
    border-bottom: 0;
    white-space: pre-wrap;
    background: #f3c7be;
    box-shadow: 1px 1px 17px 2px rgba(0, 0, 0, 0.3); }
  .x-dock-layout-dragging-hint::before {
    content: "";
    display: block;
    border: 2px solid red;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    z-index: 1;
    pointer-events: none; }

.x-dock-layout-container .flexlayout__layout {
  background-color: #eaeaea; }

.x-dock-layout-container .flexlayout__tab_header_outer, .x-dock-layout-container .flexlayout__tab_header_inner {
  width: 100%;
  font-weight: 500;
  font-size: 1.23rem; }

.x-dock-layout-container .flexlayout__splitter {
  background-color: #eaeaea; }
  .x-dock-layout-container .flexlayout__splitter:hover {
    background-color: #eaeaea; }
  .x-dock-layout-container .flexlayout__splitter_drag {
    background-color: rgba(183, 183, 183, 0.5); }

.x-dock-layout-container .flexlayout__tabset {
  border-radius: 7px;
  background: white; }

.x-dock-layout-container .flexlayout__border_left, .x-dock-layout-container .flexlayout__border_top, .x-dock-layout-container .flexlayout__border_right, .x-dock-layout-container .flexlayout__border_bottom {
  background-color: #eaeaea;
  border: 0; }

.x-dock-layout-container .flexlayout__tab {
  border-radius: 7px;
  background: white; }

.x-dock-layout-container .flexlayout__tab_button {
  cursor: default;
  display: flex;
  margin: 0;
  border-radius: 0;
  float: none;
  line-height: 30px;
  padding: 0 0 0 10px;
  box-shadow: none; }
  .x-dock-layout-container .flexlayout__tab_button_content {
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
    font-weight: bold;
    line-height: 14px;
    text-transform: capitalize; }
  .x-dock-layout-container .flexlayout__tab_button--selected {
    background-color: transparent; }
  .x-dock-layout-container .flexlayout__tab_button_trailing {
    cursor: pointer;
    float: right;
    width: 30px;
    height: 30px;
    margin: 0;
    flex-shrink: 0;
    background: none !important; }
    .x-dock-layout-container .flexlayout__tab_button_trailing::after {
      content: "";
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgNi40MUwxNy41OSA1IDEyIDEwLjU5IDYuNDEgNSA1IDYuNDEgMTAuNTkgMTIgNSAxNy41OSA2LjQxIDE5IDEyIDEzLjQxIDE3LjU5IDE5IDE5IDE3LjU5IDEzLjQxIDEyeiIvPjwvc3ZnPg==);
      width: 26px;
      height: 26px;
      display: block;
      margin: 2px;
      border-radius: 13px;
      opacity: 0.5; }
    .x-dock-layout-container .flexlayout__tab_button_trailing:active::after {
      opacity: 0.75; }
    .x-dock-layout-container .flexlayout__tab_button_trailing:hover::after {
      background-color: #aaa; }

.x-dock-layout-container .flexlayout__tabset-selected {
  background-image: none; }

/* Transition mixins */
/* animation mixin */
/* Filter */
/* Transform mixins */
/* Calculate text color base on background color of container element */
html {
  font-size: 13px; }

body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: #000; }

::selection {
  background-color: rgba(33, 124, 243, 0.3);
  color: #000; }

#root {
  transition: opacity 1s;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 1;
  opacity: 1;
  outline: none; }

* {
  box-sizing: border-box; }

a {
  color: #08c; }

h1 {
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

h2 {
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.x-icon {
  width: 100%;
  height: 100%;
  color: #217cf3; }

.x-sm-icon {
  background: transparent;
  outline: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 2px;
  border: 1px solid transparent;
  border-radius: 3px;
  height: 20px;
  margin: 0 5px -5px;
  border-color: #094da5;
  background-color: #fff; }
  .x-sm-icon:hover {
    background-color: rgba(136, 136, 136, 0.2); }
  .x-sm-icon:active {
    border-color: rgba(136, 136, 136, 0.5); }
  .x-sm-icon.active {
    background-color: rgba(136, 136, 136, 0.5); }
    .x-sm-icon.active:hover {
      background-color: rgba(136, 136, 136, 0.6); }
  .x-sm-icon.checked, .x-sm-icon.active {
    border-color: #094da5;
    box-shadow: inset 0px 0px 0px 2px #9ac3fa;
    background-color: #fff; }
    .x-sm-icon.checked:hover, .x-sm-icon.active:hover {
      background-color: #fff; }
  .x-sm-icon .x-icon {
    pointer-events: none; }
  .x-sm-icon[disabled], .x-sm-icon.disabled {
    cursor: not-allowed;
    opacity: 0.45; }
    .x-sm-icon[disabled] .x-icon, .x-sm-icon.disabled .x-icon {
      color: #484848; }
    .x-sm-icon[disabled]:hover, .x-sm-icon.disabled:hover {
      background: transparent; }

.x-loading > * {
  opacity: 0.5; }

.x-loading::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5em 0 0 -5em;
  display: inline-block;
  font-size: 2px;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-name: rotation;
  -webkit-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count: infinite;
  -moz-animation-delay: 0;
  -moz-animation-duration: 1.5s;
  -moz-animation-name: rotation;
  -moz-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -moz-animation-iteration-count: infinite;
  animation-delay: 0;
  animation-duration: 1.5s;
  animation-name: rotation;
  animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.x-loading-icon {
  display: inline-block;
  font-size: 2px;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-name: rotation;
  -webkit-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count: infinite;
  -moz-animation-delay: 0;
  -moz-animation-duration: 1.5s;
  -moz-animation-name: rotation;
  -moz-animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  -moz-animation-iteration-count: infinite;
  animation-delay: 0;
  animation-duration: 1.5s;
  animation-name: rotation;
  animation-fill-mode: forwards;
  /* this prevents the animation from restarting! */
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.error {
  color: #cc0707; }

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.x-app-title {
  display: none; }

.x-title-bar {
  height: 40px;
  line-height: 40px;
  text-align: center; }

.x-taskbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 0px;
  padding: 0 5px;
  height: 100vh;
  background: #ddd; }
  .x-taskbar > .toolbar-btn {
    background: transparent;
    outline: none;
    width: 44px;
    height: 44px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 6px;
    border: 1px solid transparent;
    border-radius: 9px;
    display: block;
    margin: 5px 0; }
    .x-taskbar > .toolbar-btn:hover {
      background-color: rgba(136, 136, 136, 0.2); }
    .x-taskbar > .toolbar-btn:active {
      border-color: rgba(136, 136, 136, 0.5); }
    .x-taskbar > .toolbar-btn.active {
      background-color: rgba(136, 136, 136, 0.5); }
      .x-taskbar > .toolbar-btn.active:hover {
        background-color: rgba(136, 136, 136, 0.6); }
    .x-taskbar > .toolbar-btn.checked, .x-taskbar > .toolbar-btn.active {
      border-color: #094da5;
      box-shadow: inset 0px 0px 0px 2px #9ac3fa;
      background-color: #fff; }
      .x-taskbar > .toolbar-btn.checked:hover, .x-taskbar > .toolbar-btn.active:hover {
        background-color: #fff; }
    .x-taskbar > .toolbar-btn .x-icon {
      pointer-events: none; }
    .x-taskbar > .toolbar-btn[disabled], .x-taskbar > .toolbar-btn.disabled {
      cursor: not-allowed;
      opacity: 0.45; }
      .x-taskbar > .toolbar-btn[disabled] .x-icon, .x-taskbar > .toolbar-btn.disabled .x-icon {
        color: #484848; }
      .x-taskbar > .toolbar-btn[disabled]:hover, .x-taskbar > .toolbar-btn.disabled:hover {
        background: transparent; }
  .x-taskbar > hr {
    margin: 0;
    border: 0;
    background: rgba(136, 136, 136, 0.5);
    height: 1px; }

.x-container {
  position: absolute;
  width: calc(100% - 0px);
  height: calc(100vh - 0px);
  padding: 20px;
  overflow: auto;
  left: 0px; }

.x-dock-layout-container {
  position: absolute;
  width: calc(100% - 0px);
  height: calc(100vh - 0px);
  top: 0;
  left: 0px; }

@media only screen and (max-device-width: 1023px) {
  .x-dock-layout-container {
    height: 1500px; } }

.x-login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .x-login-form__Logo {
    display: block;
    max-width: 200px;
    pointer-events: none;
    margin-bottom: 1rem; }
  .x-login-form__Content {
    background: #ddd;
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: inset 0 0 9px 0 #00000014;
    max-width: 26rem;
    width: 100%;
    margin-bottom: 1rem; }
    .x-login-form__Content .ui.button {
      min-width: 7rem; }
    .x-login-form__Content h1,
    .x-login-form__Content h2 {
      text-align: center; }
  .x-login-form__Footer {
    background: rgba(221, 221, 221, 0.2);
    border-radius: 1rem;
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    max-height: 50vh;
    overflow: auto; }
    .x-login-form__Footer h1,
    .x-login-form__Footer h2,
    .x-login-form__Footer h3 {
      font-size: 1.5rem;
      text-align: center; }

.x-log-viewer > .content {
  height: 400px; }

.x-log-viewer > .header > .menu {
  background: transparent;
  display: inline-flex;
  float: right;
  margin: 0 1em -1em 0;
  box-shadow: none;
  border: 0; }

.x-log-viewer__info {
  color: #1f22b1;
  background: white !important; }

.x-log-viewer__warn {
  color: #636410;
  background: #fbffd6 !important; }

.x-log-viewer__error {
  color: red;
  background: #ffe0e0 !important; }

.x-log-viewer__debug {
  background: #eaeaea !important; }

.x-log-viewer .ReactVirtualized__Table__row {
  align-items: start; }

.x-log-viewer .ReactVirtualized__Table__Grid {
  background: transparent;
  border: 1px solid rgba(136, 136, 136, 0.2); }

.hidden {
  display: none !important; }

.fullWidthHeight {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute !important;
  overflow: hidden; }

.x-search-customer-wrapper {
  position: relative; }
  .x-search-customer-wrapper > div.x-search-customer-item-wrapper {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0;
    font-size: 90%;
    overflow: auto;
    border: 1px solid #d5d5d5;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;
    max-height: 50vh !important;
    position: absolute !important;
    left: 0 !important;
    top: 35px !important; }
  .x-search-customer-wrapper > div:empty {
    display: none; }
  .x-search-customer-wrapper .x-search-customer-item {
    padding: 4px 6px;
    background: #fff;
    opacity: 1;
    border-bottom: 1px solid #e1e1e1; }
    .x-search-customer-wrapper .x-search-customer-item:last-child {
      border-bottom: none; }
    .x-search-customer-wrapper .x-search-customer-item.active {
      background-color: #82b5f8; }
    .x-search-customer-wrapper .x-search-customer-item p {
      margin: 0;
      margin-bottom: 3px; }

.x-search-customer {
  position: relative; }

.x-clear-customer {
  position: absolute;
  top: 3px;
  right: -1px;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 50% !important;
  opacity: 0.5; }
  .x-clear-customer:hover {
    opacity: 1;
    box-shadow: 0 0 0 1px #22242626 inset !important;
    background-color: #0000001a !important; }

@media only screen and (max-device-width: 1023px) {
  .x-search-customer-wrapper .x-search-customer-item {
    font-size: 15px; }
  .x-clear-customer {
    font-size: 14px !important; } }

.x-mqtt-input {
  background-color: #ffd !important; }

.ui.button {
  min-width: 85px; }
  .ui.button.icon {
    min-width: auto; }

.x-search-product-wrapper {
  position: relative; }
  .x-search-product-wrapper > div.x-search-product-item-wrapper {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0;
    font-size: 90%;
    overflow: auto;
    border: 1px solid #d5d5d5;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;
    max-height: 50vh !important;
    position: fixed !important; }
  .x-search-product-wrapper > div:empty {
    display: none; }
  .x-search-product-wrapper .x-search-product-item {
    padding: 4px 6px;
    background: #fff;
    opacity: 1;
    border-bottom: 1px solid #e1e1e1; }
    .x-search-product-wrapper .x-search-product-item:last-child {
      border-bottom: none; }
    .x-search-product-wrapper .x-search-product-item.active {
      background-color: #82b5f8; }
    .x-search-product-wrapper .x-search-product-item p {
      margin: 0;
      margin-bottom: 3px; }

@media only screen and (max-device-width: 1023px) {
  .ui.button {
    min-width: 70px; } }

.x-close-menu {
  display: none !important; }

.x-menu-toggle .x-close-menu {
  display: block !important;
  z-index: 10000;
  top: 0;
  right: 0;
  position: absolute; }

.x-menu-toggle.visible {
  width: 222px !important;
  display: block !important; }

.ui.left.sidebar {
  width: 222px; }

.pusher {
  -webkit-transform: translate3d(222px, 0, 0) !important;
          transform: translate3d(222px, 0, 0) !important;
  width: calc(100% - 222px); }
  .pusher .ui.basic.segment {
    width: 100%;
    height: 100%;
    position: absolute; }
    .pusher .ui.basic.segment h3 {
      height: 20px; }
    .pusher .ui.basic.segment .x-flex {
      display: flex;
      flex-direction: column;
      height: calc(100% - 20px); }
      .pusher .ui.basic.segment .x-flex > :first-child {
        flex: 0 0; }
      .pusher .ui.basic.segment .x-flex > :last-child {
        flex: 1 1;
        position: relative; }

.hidden-btn {
  width: 1px;
  height: 1px;
  border: none;
  opacity: 0 !important;
  overflow: hidden; }
  .hidden-btn:after {
    opacity: 0 !important; }

.x-header-block-wrapper::after {
  clear: both;
  content: ""; }

.x-header-block {
  padding-bottom: 3px;
  line-height: 28px; }
  .x-header-block > h3 {
    display: inline-block;
    font-size: 22px !important;
    margin: 0 !important; }
  .x-header-block > span.x-role {
    float: right;
    padding: 0 30px; }
  .x-header-block > div {
    float: right; }
  .x-header-block > button.ui.button {
    display: none; }

@media only screen and (max-device-width: 1023px) {
  .x-header-block > button.ui.button {
    border: "none";
    box-shadow: "none";
    display: inline-block; }
  .x-header-block > span.x-role {
    display: none; } }

.ui.table .cell-input {
  padding-left: 0.3em;
  padding-right: 0.3em; }

.ui.table .action-cell {
  padding-left: 0;
  padding-right: 0;
  text-align: center; }
  .ui.table .action-cell button {
    padding: 2px !important;
    margin: 5px !important; }

.x-details {
  display: flex;
  flex-direction: row;
  height: calc(100% - 20px); }
  .x-details .x-table-wrapper {
    flex-grow: 1;
    overflow: auto; }
    .x-details .x-table-wrapper tbody tr {
      height: 70px; }
  .x-details .x-info-wrapper {
    flex-grow: 0;
    flex-basis: 400px;
    margin: 0;
    overflow-y: auto; }

.x-edit-contact-bl {
  overflow: auto;
  margin-top: -1em;
  margin-bottom: 0.75em; }

.ui.basic.blue.button.x-new-edit-contact-btn {
  border: none !important;
  box-shadow: none !important; }
  .ui.basic.blue.button.x-new-edit-contact-btn:hover {
    text-decoration: underline;
    box-shadow: none !important; }

.ui.basic.blue.button.x-explore-customer {
  border: none !important;
  box-shadow: none !important;
  border-radius: 2px;
  padding: 2px;
  margin: 0;
  min-width: 'auto';
  margin-left: 4px; }
  .ui.basic.blue.button.x-explore-customer:hover {
    box-shadow: none !important;
    background-color: #cacaca !important; }

.x-customer-cell {
  margin-top: -6px !important;
  font-size: 15px !important; }
  .x-customer-cell small {
    font-size: 13px; }

.x-cell-center {
  margin: 0 auto !important; }

.x-cell-right {
  float: right !important;
  padding-right: 5px; }

/* edit-export-mobile */
@media only screen and (max-device-width: 1023px) {
  .x-details {
    display: block;
    height: auto;
    overflow: auto;
    padding-top: 15px;
    padding-bottom: 50px; }
    .x-details .x-table-wrapper {
      overflow: initial; } }

/* edit-export-mobile */
.x-input-button > .ui.button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 42px; }
  .x-input-button > .ui.button:first-child > .icon {
    margin-left: auto;
    margin-right: auto; }

.x-input-button > .ui.button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 42px; }
  .x-input-button > .ui.button:last-child > .icon {
    margin-left: auto;
    margin-right: auto; }

.x-input-button > .ui.input input {
  border-radius: 0; }

/* text bigger than on mobile */
@media only screen and (max-device-width: 1023px) {
  .ui.form .field > label {
    font-size: 16px; }
  .ui.form input {
    font-size: 16px !important; }
  .ui.search.dropdown > .text,
  .ui.dropdown > .text {
    font-size: 16px !important; }
  .ui.dropdown .menu > .item {
    font-size: 15px !important; } }

/* text bigger than on mobile */
.x-table-summary.ui.compact.table th {
  padding: 0.5em 0.15em; }

.x-table-summary.ui.compact.table td {
  padding: 0.15em 0.2em; }

.x-table-summary .ui.button {
  color: #217cf3 !important;
  box-shadow: none;
  padding: 2px 5px; }

table .ui.label {
  padding-top: 2px !important;
  padding-bottom: 3px !important; }

table tfoot tr th {
  font-weight: bold !important; }

.x-money-info {
  font-size: 1.15em; }
  .x-money-info .x-label {
    width: 40%;
    text-align: right;
    display: inline-block;
    font-weight: bold;
    padding-right: 8px; }

.ui.form .field :disabled,
.ui.disabled.dropdown {
  opacity: 0.65; }

.pos-right .rdtPicker {
  right: 0; }

.x-cs-mr {
  margin-top: -5px; }

.ui.icon.input > input {
  padding-right: 0 !important; }

/* implement menu MB */
@media only screen and (max-device-width: 1023px) {
  .ui.visible.push.sidebar {
    display: none;
    width: 0px; }
  .pusher {
    display: contents; } }

.x-mb-delivery-item {
  margin-bottom: 10px; }
  .x-mb-delivery-item:nth-child(even) {
    background-color: #f3f3f3; }
  .x-mb-delivery-item .ui.list > .item {
    padding: 0; }
  .x-mb-delivery-item .x-mb-row {
    display: flex;
    border-bottom: 1px solid #e6e6e6; }
    .x-mb-delivery-item .x-mb-row > * {
      flex: 1 1 auto; }
    .x-mb-delivery-item .x-mb-row > :first-child {
      text-align: left;
      padding: 4px 0px 4px 8px; }
    .x-mb-delivery-item .x-mb-row > :last-child {
      text-align: right;
      font-weight: bold;
      padding: 4px 8px 4px 0px; }
    .x-mb-delivery-item .x-mb-row .ui.basic.label {
      background: none !important;
      border: none !important; }
  .x-mb-delivery-item .ui.toggle.checkbox {
    margin-top: -3px;
    margin-bottom: -4px; }
  .x-mb-delivery-item .ui.toggle.checkbox.colored .box:before,
  .x-mb-delivery-item .ui.toggle.checkbox.colored label:before {
    background: lightpink !important; }
  .x-mb-delivery-item .ui.toggle.checkbox .box:before,
  .x-mb-delivery-item .ui.toggle.checkbox label:before {
    background: #aaa !important; }

.x-page {
  overflow: hidden; }

@media only screen and (max-device-width: 1023px) {
  .x-page {
    overflow-y: auto; }
  .pusher .ui.basic.segment .x-flex {
    display: block; } }

.file-uploader {
  position: relative; }
  .file-uploader > .x-file-name {
    color: #2c51f8; }

.x-dont-display {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  border: none;
  opacity: 0;
  top: -1px;
  left: -1px;
  position: fixed;
  overflow: hidden; }

.x-loan-detail > .item {
  display: block;
  width: 50%;
  float: left; }
  .x-loan-detail > .item > .x-label {
    font-weight: bold;
    width: 170px;
    text-align: right;
    display: inline-block;
    margin-right: 5px; }

.x-mobile-supported {
  margin-right: -10px !important;
  margin-left: 0px !important; }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center; }

.rdtPicker table {
  width: 100%;
  margin: 0; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px; }

.rdtPicker td {
  cursor: pointer; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 100px;
  text-transform: capitalize; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
  text-transform: capitalize; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee; }

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

/* Transition mixins */
/* animation mixin */
/* Filter */
/* Transform mixins */
/* Calculate text color base on background color of container element */
.x-chart-panel__top {
  position: absolute;
  background-color: transparent;
  top: -36px;
  width: 100%;
  text-align: center;
  border-radius: 7px;
  z-index: 10;
  border-bottom: 5px solid transparent;
  transition: top 0.5s 0.35s; }
  .x-chart-panel__top:hover {
    top: 0; }
    .x-chart-panel__top:hover .x-chart-panel__toolbar {
      overflow: initial;
      background-color: #d0d0d0; }
    .x-chart-panel__top:hover .x-chart-panel__toolbar-inner {
      opacity: 1; }

.x-chart-panel__toolbar {
  display: inline-block;
  background-color: #e68f7d;
  border-radius: 7px;
  padding: 4px 5px;
  height: 42px;
  max-width: 100%;
  transition: background-color 0.5s 0.35s; }

.x-chart-panel__toolbar-inner {
  opacity: 0;
  display: flex;
  align-items: center;
  transition: opacity 0.5s 0.35s; }
  .x-chart-panel__toolbar-inner > * {
    margin: 0 2px;
    display: inline-block; }
  .x-chart-panel__toolbar-inner > button {
    background: transparent;
    outline: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 4.5px; }
    .x-chart-panel__toolbar-inner > button:hover {
      background-color: rgba(136, 136, 136, 0.2); }
    .x-chart-panel__toolbar-inner > button:active {
      border-color: rgba(136, 136, 136, 0.5); }
    .x-chart-panel__toolbar-inner > button.active {
      background-color: rgba(136, 136, 136, 0.5); }
      .x-chart-panel__toolbar-inner > button.active:hover {
        background-color: rgba(136, 136, 136, 0.6); }
    .x-chart-panel__toolbar-inner > button.checked, .x-chart-panel__toolbar-inner > button.active {
      border-color: #094da5;
      box-shadow: inset 0px 0px 0px 2px #9ac3fa;
      background-color: #fff; }
      .x-chart-panel__toolbar-inner > button.checked:hover, .x-chart-panel__toolbar-inner > button.active:hover {
        background-color: #fff; }
    .x-chart-panel__toolbar-inner > button .x-icon {
      pointer-events: none; }
    .x-chart-panel__toolbar-inner > button[disabled], .x-chart-panel__toolbar-inner > button.disabled {
      cursor: not-allowed;
      opacity: 0.45; }
      .x-chart-panel__toolbar-inner > button[disabled] .x-icon, .x-chart-panel__toolbar-inner > button.disabled .x-icon {
        color: #484848; }
      .x-chart-panel__toolbar-inner > button[disabled]:hover, .x-chart-panel__toolbar-inner > button.disabled:hover {
        background: transparent; }
  .x-chart-panel__toolbar-inner .ui.dropdown {
    padding: 0.3em 1.5em 0.3em .5em;
    min-height: 1.8em;
    margin: 3px 2px; }
    .x-chart-panel__toolbar-inner .ui.dropdown > .dropdown.icon {
      padding: 0.5em .3em; }
    .x-chart-panel__toolbar-inner .ui.dropdown > .menu > .item {
      padding: 0.5em 0.75em !important; }

.x-chart-panel__view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid #217cf3;
  border-top: 0; }
  .x-chart-panel__view .x-grid-chart {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute; }
    .x-chart-panel__view .x-grid-chart .x-datatable__cell {
      width: 100%; }
  .x-chart-panel__view .x-no-content {
    margin-top: 1.5em;
    text-align: center; }

.x-chart-panel__filter + .x-chart-panel__view {
  padding-bottom: 20px; }

.x-chart-panel__filter {
  position: absolute;
  background-color: transparent;
  bottom: 0;
  width: 100%;
  background-color: #fae9e5;
  border-top: 1px solid #f3c7be;
  border-left: 1px solid #217cf3;
  border-right: 1px solid #217cf3;
  border-bottom: 1px solid #217cf3;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: 10; }
  .x-chart-panel__filter:hover {
    height: auto;
    border-bottom: none; }
    .x-chart-panel__filter:hover .x-chart-panel__filter-body {
      display: block; }
    .x-chart-panel__filter:hover .x-chart-panel__filter-header {
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0.1); }
      .x-chart-panel__filter:hover .x-chart-panel__filter-header::after {
        content: ""; }
  .x-chart-panel__filter-body {
    display: none;
    overflow: hidden;
    padding: 0px 8px; }
    .x-chart-panel__filter-body ul {
      padding: 0;
      margin: 0.5em 0; }
    .x-chart-panel__filter-body li {
      display: inline-block;
      max-width: 20em;
      text-overflow: ellipsis;
      overflow: hidden;
      list-style: none;
      margin-right: 1em; }
  .x-chart-panel__filter-header {
    text-align: center;
    padding: 1px 8px;
    transition: background-color 0.5s; }
    .x-chart-panel__filter-header::after {
      content: "..."; }
  .x-chart-panel__filter + .x-chart-panel__view {
    padding-bottom: 20px; }

.x-chart-panel__notify {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -1em;
  line-height: 1.1em; }

.x-chart-panel__resizeIframe {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  border: none;
  z-index: -1; }

#x-chartjs-tooltip {
  font-size: 0.9rem;
  padding: 4px 6px;
  pointer-events: none;
  color: #fff;
  background-color: #696969;
  border-radius: 3px;
  position: fixed;
  z-index: 100;
  white-space: pre-wrap; }

.x-selectbox {
  width: 220px;
  position: relative;
  display: inline-block; }
  .x-selectbox.disabled {
    cursor: default;
    pointer-events: none; }
  .x-selectbox > .ui.button {
    text-align: left;
    padding-left: 1em;
    background-color: #fff !important;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: none; }
    .x-selectbox > .ui.button > *:first-child {
      opacity: 0.5;
      display: inline-block;
      color: #000;
      white-space: nowrap;
      width: calc(100% - 0.75em);
      overflow: hidden; }
      .x-selectbox > .ui.button > *:first-child.selected {
        opacity: 1; }
      .x-selectbox > .ui.button > *:first-child::before {
        content: "";
        display: inline-block; }
    .x-selectbox > .ui.button > .x-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0.5em;
      top: 0.5em; }

.x-selectbox__popup.ui.popup {
  max-width: none; }

.x-selectbox__popup .ui.menu {
  min-width: 300px;
  max-height: 400px;
  overflow: auto; }
  .x-selectbox__popup .ui.menu .ui.checkbox {
    pointer-events: none; }
  .x-selectbox__popup .ui.menu .item.active {
    background-color: #82b5f8; }
    .x-selectbox__popup .ui.menu .item.active:hover {
      background-color: #6aa7f7; }
  .x-selectbox__popup .ui.menu .item {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
    margin-bottom: 2px !important; }

.x-datatable {
  position: relative; }
  .x-datatable__autoSize {
    position: relative; }
    .x-datatable__autoSize > iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 0;
      opacity: 0;
      pointer-events: none; }
  .x-datatable__noRowsMessage {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    left: 0;
    top: 0;
    align-items: center; }
    .x-datatable__noRowsMessage > i {
      display: block;
      text-align: center;
      width: 100%;
      font-style: normal;
      color: #888; }
    .x-datatable__noRowsMessage .error {
      color: #cc0707; }

.public_fixedDataTable_main {
  border: 0;
  background: #f8f8f8; }

.public_fixedDataTableRow_fixedColumnsDivider {
  border-color: rgba(0, 0, 0, 0.15); }

.public_fixedDataTableRow_columnsShadow.public_fixedDataTableRow_columnsRightShadow {
  margin-left: 1px; }

.public_fixedDataTableRow_main {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .public_fixedDataTableRow_main.selected.public_fixedDataTableRow_highlighted,
  .public_fixedDataTableRow_main.selected.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: #bcd8fb; }
  .public_fixedDataTableRow_main.selected.public_fixedDataTableRow_main,
  .public_fixedDataTableRow_main.selected.public_fixedDataTableRow_main .public_fixedDataTableCell_main {
    background-color: #bcd8fb; }
  .public_fixedDataTableRow_main.selected:hover.public_fixedDataTableRow_highlighted,
  .public_fixedDataTableRow_main.selected:hover.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: #a1c6f6; }
  .public_fixedDataTableRow_main.selected:hover.public_fixedDataTableRow_main,
  .public_fixedDataTableRow_main.selected:hover.public_fixedDataTableRow_main .public_fixedDataTableCell_main {
    background-color: #a1c6f6; }
  .public_fixedDataTableRow_main.public_fixedDataTableRow_highlighted,
  .public_fixedDataTableRow_main.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background: #fcfcfc; }
  .public_fixedDataTableRow_main .public_fixedDataTableCell_main {
    border-right: 0; }
  .public_fixedDataTableRow_main .public_fixedDataTableCell_cellContent {
    padding: 0.6rem 0.25rem; }
  .public_fixedDataTableRow_main.x-datatable__groupHeaderRow {
    background: white !important; }
    .public_fixedDataTableRow_main.x-datatable__groupHeaderRow .fixedDataTableCellGroupLayout_cellGroup {
      width: 100% !important; }
    .public_fixedDataTableRow_main.x-datatable__groupHeaderRow .public_fixedDataTableCell_main:first-child {
      width: 100% !important; }
    .public_fixedDataTableRow_main.x-datatable__groupHeaderRow .public_fixedDataTableCell_main + .public_fixedDataTableCell_main {
      width: 0 !important; }
    .public_fixedDataTableRow_main.x-datatable__groupHeaderRow .public_fixedDataTableCell_main {
      background-color: rgba(125, 139, 230, 0.25); }
  .public_fixedDataTableRow_main.public_fixedDataTable_footer {
    background: #f6f7f8;
    border-top: 1px solid #d3d3d3; }
    .public_fixedDataTableRow_main.public_fixedDataTable_footer .public_fixedDataTableCell_main {
      border-top: 0; }
  .public_fixedDataTableRow_main .ui.fitted.checkbox {
    margin-top: 0; }
    .public_fixedDataTableRow_main .ui.fitted.checkbox > label {
      margin: 0; }
  .public_fixedDataTableRow_main .ui.buttons {
    margin: -0.5rem; }
  .public_fixedDataTableRow_main .ui.basic.button {
    color: #217cf3 !important;
    box-shadow: none; }
  .public_fixedDataTableRow_main .fixedDataTableCellLayout_columnResizerContainer {
    border-right: 1px solid rgba(0, 0, 0, 0.05); }
  .public_fixedDataTableRow_main .x-wrap-cell {
    white-space: nowrap; }

