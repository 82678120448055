@import './vars';
@import './mixins';

.x-tab-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    & > * {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        & > .ui.menu {
            flex-grow: 0;
        }
        & > .ui.tab {
            flex-grow: 1;
            display: flex;

            & > * {
                flex-grow: 1;
            }
        }
    }
}

.ui.input {
    > .x-icon {
        width: 20px;
        height: 20px;
        margin: 7px 0 0 -28px;
        pointer-events: none;
    }
}

body ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 0;
}

.ui.dimmer {
    background-color: rgba(0, 0, 0, .3);
}

.ui.label:empty {
    display: none !important;
}

.ui.form .field > input:focus,
.ui.form .field > label > input:focus,
.ui.input > input:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.x-selectbox > .ui.button:focus {
    border-color: $primary-hilight-color;
    box-shadow: inset 0 0 0 0.2rem rgba($primary-hilight-color, 0.3);
}

.ui.form {
    .fields.wrap {
        flex-wrap: wrap;

        .small.buttons {
            margin: 4px 0;
            & > .ui.button {
                padding: 0.5em 1em;
            }
        }
    }
}

.ui.dropdown > .text {
    color: $input-text-color;
}

.ui.modal {
    position: static; // set position static to fix error in IE

    & > .close.icon {
        top: 0;
        right: 0;
        opacity: 0.5;
        font-size: 2rem;
        padding: 0.25em;
        width: 1.25em;

        &::before {
            content: "×";
            color: #000;
        }
        &:hover {
            opacity: 1;
        }
    }
    & > .header {
        background: $modal-content-back-color;
        border-bottom: none;
    }
    & > .content {
        background: $modal-content-back-color;
    }
    & > .actions {
        background: $modal-content-back-color;
        border-top: none;
        .ui.button {
            min-width: 7em;
        }
    }
}

.ui.inverted.popup {
    background: $inverted-back-color;

    &:before {
        background: $inverted-back-color !important;
    }
}

.blurring.dimmed.dimmable {
    & >:not(.dimmer) {
        @include filter(none);
    }
    & > #root {
        @include filter(blur(5px) grayscale(.7));
    }
}

.ui.button.x-disabled, a.item.x-disabled {
    cursor: not-allowed;
    opacity: 0.45;
}

.ui.button {
    font-weight: normal !important;
    color: $view-body-text-color;
    background: rgba($view-body-text-color, 0.1);
    &:hover {
        color: $view-body-text-color;
        background: rgba($view-body-text-color, 0.15);
    }
}

.ui.table {
  td {
    padding-top: .3em !important;
    padding-bottom: .3em !important;
  }
}