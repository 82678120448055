@import "./vars";
@import "./mixins";
@import "./toolbar";

html {
  font-size: $font-size-base;
}

body {
  margin: 0;
  padding: 0;
  font-size: $font-size-base;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: $view-body-text-color;
}

::selection {
  background-color: rgba($primary-hilight-color, 0.3);
  color: $view-body-text-color;
}

#root {
  @include transition(opacity 1s);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: $view-body-back-color;
  z-index: 1;
  opacity: 1;
  outline: none;
}

* {
  box-sizing: border-box;
}

a {
  color: #08c;
}

h1 {
  font-weight: normal;
  font-family: $font-family;
}

h2 {
  font-weight: normal;
  font-family: $font-family;
}

.x-icon {
  width: 100%;
  height: 100%;
  color: $primary-hilight-color;
}
// .x-sm-icon {
//   width: 16px;
//   height: 16px;
//   padding: 2px;
// }
.x-sm-icon {
  @include toolbar-btn($width: 25px, $padding: 2px);
  height: 20px;
  margin: 0 5px -5px;
  border-color: darken($primary-hilight-color, 20%);
  background-color: #fff;
}

@mixin x-loading-icon {
  display: inline-block;
  font-size: 2px;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  @include animation($delay: 0, $duration: 1.5s, $animation: rotation);
}

.x-loading > * {
  opacity: 0.5;
}

.x-loading::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5em 0 0 -5em;
  @include x-loading-icon;
}

.x-loading-icon {
  @include x-loading-icon;
}

.error {
  color: $danger-color;
}

@keyframes rotation {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(359deg));
  }
}

.x-app-title {
  display: none;
}

.x-title-bar {
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.x-taskbar {
  position: fixed;
  left: 0;
  top: 0;
  width: $taskbar-width;
  padding: 0 5px;
  height: 100vh;
  background: $grey-back-color;
  > .toolbar-btn {
    @include toolbar-btn($width: 44px, $padding: 6px);
    display: block;
    margin: 5px 0;
  }
  > hr {
    margin: 0;
    border: 0;
    background: rgba($grey-line-color, 0.5);
    height: 1px;
  }
}

.x-container {
  position: absolute;
  width: calc(100% - #{$taskbar-width});
  height: calc(100vh - #{$statusbar-height});
  padding: 20px;
  overflow: auto;
  left: $taskbar-width;
}

.x-dock-layout-container {
  position: absolute;
  width: calc(100% - #{$taskbar-width});
  height: calc(100vh - #{$statusbar-height});
  top: 0;
  left: $taskbar-width;
}

@media only screen and (max-device-width: 1023px) {
  .x-dock-layout-container {
    height: 1500px;
  }
}

.x-login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__Logo {
    display: block;
    max-width: 200px;
    pointer-events: none;
    margin-bottom: 1rem;
  }

  &__Content {
    background: #ddd;
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: inset 0 0 9px 0 #00000014;
    max-width: 26rem;
    width: 100%;
    margin-bottom: 1rem;

    .ui.button {
      min-width: 7rem;
    }

    h1,
    h2 {
      text-align: center;
    }
  }

  &__Footer {
    background: rgba($grey-back-color, 0.2);
    border-radius: 1rem;
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    max-height: 50vh;
    overflow: auto;

    h1,
    h2,
    h3 {
      font-size: 1.5rem;
      text-align: center;
    }
  }
}

.x-log-viewer {
  & > .content {
    height: 400px;
  }

  & > .header > .menu {
    background: transparent;
    display: inline-flex;
    float: right;
    margin: 0 1em -1em 0;
    box-shadow: none;
    border: 0;
  }

  &__info {
    color: #1f22b1;
    background: white !important;
  }
  &__warn {
    color: #636410;
    background: #fbffd6 !important;
  }
  &__error {
    color: red;
    background: #ffe0e0 !important;
  }
  &__debug {
    background: #eaeaea !important;
  }
  .ReactVirtualized__Table__row {
    align-items: start;
  }
  .ReactVirtualized__Table__Grid {
    background: transparent;
    border: 1px solid rgba($grey-line-color, 0.2);
  }
}

.hidden {
  display: none !important;
}

.fullWidthHeight {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute !important;
  overflow: hidden;
}

.x-search-customer-wrapper {
  position: relative;
  & > div.x-search-customer-item-wrapper {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0;
    font-size: 90%;
    overflow: auto;
    //
    border: 1px solid lighten($color: $grey-line-color, $amount: 30%);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;
    max-height: 50vh !important;
    position: absolute !important;
    left: 0 !important;
    top: 35px !important;
  }

  & > div:empty {
    display: none;
  }

  & .x-search-customer-item {
    padding: 4px 6px;
    background: #fff;
    opacity: 1;
    border-bottom: 1px solid lighten($color: $grey-line-color, $amount: 35%);
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: lighten($color: $primary-hilight-color, $amount: 20%);
    }
    & p {
      margin: 0;
      margin-bottom: 3px;
    }
  }
}

.x-search-customer {
  position: relative;
}

.x-clear-customer {
  position: absolute;
  top: 3px;
  right: -1px;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 50% !important;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    box-shadow: 0 0 0 1px #22242626 inset !important;
    background-color: #0000001a !important;
  }
}

@media only screen and (max-device-width: 1023px) {
  .x-search-customer-wrapper {
    & .x-search-customer-item {
      font-size: 15px;
    }
  }
  .x-clear-customer {
    font-size: 14px !important;
  }
}

.x-mqtt-input {
  background-color: #ffd !important;
}

.ui.button {
  min-width: 85px;

  &.icon {
    min-width: auto;
  }
}

.x-search-product-wrapper {
  position: relative;
  & > div.x-search-product-item-wrapper {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0;
    font-size: 90%;
    overflow: auto;
    border: 1px solid lighten($color: $grey-line-color, $amount: 30%);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;
    max-height: 50vh !important;
    position: fixed !important;
  }

  & > div:empty {
    display: none;
  }

  & .x-search-product-item {
    padding: 4px 6px;
    background: #fff;
    opacity: 1;
    border-bottom: 1px solid lighten($color: $grey-line-color, $amount: 35%);
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: lighten($color: $primary-hilight-color, $amount: 20%);
    }
    & p {
      margin: 0;
      margin-bottom: 3px;
    }
  }
}

@media only screen and (max-device-width: 1023px) {
  .ui.button {
    min-width: 70px;
  }
}

.x-close-menu {
  display: none !important;
}

.x-menu-toggle {
  .x-close-menu {
    display: block !important;
    z-index: 10000;
    top: 0;
    right: 0;
    position: absolute;
  }
}

.x-menu-toggle.visible {
  width: 222px !important;
  display: block !important;
}

.ui.left.sidebar {
  width: 222px;
}

.pusher {
  transform: translate3d(222px, 0, 0) !important;
  width: calc(100% - 222px);
  .ui.basic.segment {
    width: 100%;
    height: 100%;
    position: absolute;

    h3 {
      height: 20px;
    }

    .x-flex {
      display: flex;
      flex-direction: column;
      height: calc(100% - 20px);
      & > :first-child {
        flex: 0 0;
      }
      & > :last-child {
        flex: 1 1;
        position: relative;
      }
    }
  }
}

.hidden-btn {
  width: 1px;
  height: 1px;
  border: none;
  opacity: 0 !important;
  overflow: hidden;

  &:after {
    opacity: 0 !important;
  }
}

.x-header-block-wrapper::after {
  clear: both;
  content: "";
}
.x-header-block {
  padding-bottom: 3px;
  line-height: 28px;

  & > h3 {
    display: inline-block;
    font-size: 22px !important;
    margin: 0 !important;
  }
  & > span.x-role {
    float: right;
    padding: 0 30px;
  }
  & > div {
    float: right;
  }
  & > button.ui.button {
    display: none;
  }
}

@media only screen and (max-device-width: 1023px) {
  .x-header-block {
    & > button.ui.button {
      border: "none";
      box-shadow: "none";
      display: inline-block;
    }
    & > span.x-role {
      display: none;
    }
  }
}

.ui.table {
  .cell-input {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }

  .action-cell {
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    button {
      padding: 2px !important;
      margin: 5px !important;
    }
  }
}

.x-details {
  display: flex;
  flex-direction: row;
  height: calc(100% - 20px);

  .x-table-wrapper {
    flex-grow: 1;
    overflow: auto;

    tbody tr {
      height: 70px;
    }
  }

  .x-info-wrapper {
    flex-grow: 0;
    flex-basis: 400px;
    margin: 0;
    overflow-y: auto;
  }
}

.x-edit-contact-bl {
  overflow: auto;
  margin-top: -1em;
  margin-bottom: 0.75em;
}
.ui.basic.blue.button.x-new-edit-contact-btn {
  border: none !important;
  box-shadow: none !important;
  &:hover {
    text-decoration: underline;
    box-shadow: none !important;
  }
}

.ui.basic.blue.button.x-explore-customer {
  border: none !important;
  box-shadow: none !important;
  border-radius: 2px;
  padding: 2px;
  margin: 0;
  min-width: 'auto';
  margin-left: 4px;

  &:hover {
    box-shadow: none !important;
    background-color: #cacaca !important;
  }
}

.x-customer-cell {
  margin-top: -6px !important;
  font-size: 15px !important;
  small {
    font-size: 13px;
  }
}

.x-cell-center {
  margin: 0 auto !important;
}

.x-cell-right {
  float: right !important;
  padding-right: 5px;
}

/* edit-export-mobile */
@media only screen and (max-device-width: 1023px) {
  .x-details{
    display: block;
    height: auto;
    overflow: auto;
    padding-top: 15px;
    padding-bottom: 50px;

    .x-table-wrapper {
      overflow: initial;
    }
  }
}
/* edit-export-mobile */

.x-input-button {
  & > .ui.button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 42px;
    &>.icon {
      margin-left: auto;
      margin-right: auto;
    }
  }
  & > .ui.button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 42px;
    &>.icon {
      margin-left: auto;
      margin-right: auto;
    }
  }
  & >.ui.input input {
    border-radius: 0;
  }
}

/* text bigger than on mobile */
@media only screen and (max-device-width: 1023px) {
  .ui.form .field > label {
    font-size: 16px;
  }

  .ui.form input {
    font-size: 16px !important;
  }

  .ui.search.dropdown > .text,
  .ui.dropdown > .text {
    font-size: 16px !important;
  }

  .ui.dropdown .menu > .item {
    font-size: 15px !important;
  }
}
/* text bigger than on mobile */

// .x-table-summary
.x-table-summary.ui.compact.table th {
  padding: 0.5em 0.15em;
}

.x-table-summary.ui.compact.table td {
  padding: 0.15em 0.2em;
}

.x-table-summary {
  .ui.button {
    color: $primary-hilight-color !important;
    box-shadow: none;
    padding: 2px 5px;
  }
}

table .ui.label {
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}

table tfoot tr th {
  font-weight: bold !important;
}

.x-money-info {
  font-size: 1.15em;

  .x-label {
    width: 40%;
    text-align: right;
    display: inline-block;
    font-weight: bold;
    padding-right: 8px;
  }
}

.ui.form .field :disabled,
.ui.disabled.dropdown {
  opacity: 0.65;
}

.pos-right .rdtPicker {
  right: 0;
}

.x-cs-mr {
  margin-top: -5px;
}

.ui.icon.input > input {
  padding-right: 0 !important;
}
/* implement menu MB */

@media only screen and (max-device-width: 1023px) {
  .ui.visible.push.sidebar {
    display: none;
    width: 0px;
  }

  .pusher {
    display: contents;
  }
}

.x-mb-delivery-item {
  margin-bottom: 10px;

  &:nth-child(even) {
    background-color: #f3f3f3;
  }
  & .ui.list>.item {
    padding: 0;
  }

  .x-mb-row {
    display: flex;
    border-bottom: 1px solid rgb(230, 230, 230);

    & > * {
      flex: 1 1 auto;
    }
    & > :first-child {
      text-align: left;
      padding: 4px 0px 4px 8px;
    }
    & > :last-child {
      text-align: right;
      font-weight: bold;
      padding: 4px 8px 4px 0px;
    }
    
    & .ui.basic.label {
      background: none !important;
      border: none !important;
    }
  }

  .ui.toggle.checkbox{
    margin-top: -3px;
    margin-bottom: -4px;
  }
  .ui.toggle.checkbox.colored .box:before,
  .ui.toggle.checkbox.colored label:before {
    background: lightpink !important;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background: #aaa !important;
  }
  // .ui.toggle.checkbox input:checked ~ .box:before,
  // .ui.toggle.checkbox input:checked ~ label:before {
  //   background: #F25F5C !important;
  // }
}

.x-page {
  overflow: hidden;
}

@media only screen and (max-device-width: 1023px) {
  .x-page {
    overflow-y: auto;
  }

  .pusher {
    // transform: translate3d(222px, 0, 0) !important;
    // width: calc(100% - 222px);
    .ui.basic.segment {
      // width: 100%;
      // height: 100%;
      // position: absolute;
  
      // h3 {
      //   height: 20px;
      // }
  
      .x-flex {
        display: block;
        // flex-direction: column;
        // height: calc(100% - 20px);
        // & > :first-child {
        //   flex: 0 0;
        // }
        // & > :last-child {
        //   flex: 1 1;
        //   position: relative;
        // }
      }
    }
  }
}

.file-uploader {
  position: relative;

  & > .x-file-name {
    color: #2c51f8;
  }
}

.x-dont-display {
  width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    border: none;
    opacity: 0;
    top: -1px;
    left: -1px;
    position: fixed;
    overflow: hidden; 
}

.x-loan-detail {
  & >.item {
    display: block;
    width: 50%;
    float: left;
    & > .x-label {
      font-weight: bold;
      width: 170px;
      text-align: right;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.x-mobile-supported {
  margin-right: -10px !important;
  margin-left: 0px !important;
}