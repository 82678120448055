$view-body-back-color: white;
$view-body-text-color: #000; 
$grey-line-color: #888;
$grey-back-color: #ddd;
$danger-color: #cc0707;
$primary-hilight-color: #217cf3;
$important-color: #E68F7D;
// $taskbar-width: 54px;
// $statusbar-height: 32px;
$taskbar-width: 0px;
$statusbar-height: 0px;

$orange-border-color: #217cf3;
$orange-border-width : 1px;

$flexlayout-back-color: lighten($grey-back-color, 5%);
$flexlayout-corner-radius: 7px;
$flexlayout-tab-header-font-size: 1.23rem;
$flexlayout-tab-header-font-weight: 500;

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-size-base: 13px;
$font-size-medium: 12px;
$font-size-small: 11px; 

$inverted-back-color: #696969;
$inverted-text-color: #fff;

// form
$input-text-back-color: #fff; // background color of text input
$input-border-color: rgba(34,36,38,.15); // border color
$input-text-color: #000; // text color of input
$modal-content-back-color: $flexlayout-back-color; // back color of modal's content area