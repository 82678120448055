@import 'src/style/vars';

.splitter-layout {
    position: relative !important;
    
    & > .layout-splitter {
        background: $grey-back-color;
        opacity: 0;

        &:hover {
            opacity: 0.3;
        }
    }
}

// .splitter-layout {
//     position: absolute;
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;

//     .layout-pane {
//         position: relative;
//         flex: 0 0 auto;
//         overflow: auto;
//         &.layout-pane-primary {
//             flex: 1 1 auto;
//         }
//     }
    
    
//     & > .layout-splitter {
//         flex: 0 0 auto;
//         width: 4px;
//         height: 100%;
//         cursor: col-resize;
//         background-color: #ccc;
//     }
    
//     .layout-splitter:hover {
//         background-color: #bbb;
//     }
    
//     &.layout-changing {
//         cursor: col-resize;
        
//         & > .layout-splitter {
//             background-color: #aaa;
//         }
//     }
    
//     &.splitter-layout-vertical {
//         flex-direction: column;
        
//         &.layout-changing {
//             cursor: row-resize;
//         }
        
//         & > .layout-splitter {
//             width: 100%;
//             height: 4px;
//             cursor: row-resize;
//         }
//     }
// }
  
  