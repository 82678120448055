@import './vars';

.x-selectbox {
    width: 220px;
    position: relative;
    display: inline-block;

    &.disabled {
        cursor: default;
        pointer-events: none;        
    }

    & > .ui.button {
        text-align: left;
        padding-left: 1em;
        background-color: $input-text-back-color !important;
        border: 1px solid $input-border-color;
        box-shadow: none;

        & > *:first-child {
            opacity: 0.5;
            display: inline-block;
            color: $view-body-text-color;
            white-space: nowrap;
            width: calc(100% - 0.75em);
            overflow: hidden;
            &.selected {
                opacity: 1;
            }
            &::before {
                content: "";
                display: inline-block;
            }
        }
        & > .x-icon {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0.5em;
            top: 0.5em;
        }
    }
}
.x-selectbox__popup {
    &.ui.popup {
        max-width: none;
    } 
    
    .ui.menu {
        min-width: 300px;
        max-height: 400px;
        overflow: auto;

        .ui.checkbox {
            pointer-events: none;
        }

        .item.active {
            background-color: lighten($primary-hilight-color, 20%);
            &:hover {
                background-color: lighten($primary-hilight-color, 15%);
            }
        }

        .item {
            padding-top: 0.5em !important;
            padding-bottom: 0.5em !important;
            margin-bottom: 2px !important;
        }
    }
}
