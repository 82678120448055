/* Transition mixins */
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

/* animation mixin */
@mixin animation ($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count: infinite;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -moz-animation-iteration-count: infinite;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Filter */
@mixin filter($value) {
  filter: $value;
  -webkit-filter: $value;
}

/* Transform mixins */
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

/* Calculate text color base on background color of container element */
@function calc-text-color($backColor) {
  @if (lightness($backColor) > 60) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).

@mixin flex($fg: 1, $fs: null) {
    
	// Set a variable to be used by box-flex properties
	// $fg-boxflex: $fg;

	// // Box-Flex only supports a flex-grow value so let's grab the
	// // first item in the list and just return that.
	// @if type-of($fg) == 'list' {
	// 	$fg-boxflex: nth($fg, 1);
	// }

	// -webkit-box-flex: $fg-boxflex;
	// -webkit-flex: $fg $fs $fb;
	// -moz-box-flex: $fg-boxflex;
	// -moz-flex: $fg $fs $fb;
	// -ms-flex: $fg $fs $fb;
  // flex: $fg $fs $fb;

  @if ($fs == null) {
    $fs: 1;
  }
  flex-grow: $fg;
  flex-shrink: $fs;
}
